import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage({ data }) {
  const { content, featuredImage } = data.wpAboutItem;
  const image = getImage(featuredImage?.node.localFile);

  return (
    <Layout isContact>
      <SEO
        keywords={[`Henrik`, `Petersson`, `Photography`, `Contact`]}
        title="Contact"
      />
      <div className="max-w-3xl mx-auto">
        <h1 className="pb-8 text-center uppercase">Contact</h1>
        <section id="contact" className="grid md:grid-cols-2 md:gap-12 ">
          <div className="user-content pb-8">
            <div
              className="content-wrapper"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className="w-full h-full">
            <GatsbyImage image={image} alt="Portrait" />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ContactPage;

export const query = graphql`
  {
    wpAboutItem {
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
          }
        }
      }
      content
    }
  }
`;
